//#############################################################################
//Available Events for the Event Bus System
//#############################################################################

export class ToggleTextTile {
    constructor(component){
        this.component = component
    }
}

export class ToggleTeamMember {
    constructor(component){
        this.component = component
    }
}

export class ToggleSelectField {
    constructor(component) {
        this.component = component
    }
}

export class ResetEventFilters {}

export class ApplyEventFilters {
    constructor(formats, targetGroups){
        this.format = formats
        this.targetGroup = targetGroups
    }
}

<template>
    <div v-if="teamMember" class="team-member" :class="teamMemberStyle">
        <picture v-if="srcsets">
            <template v-for="[format, set] of Object.entries(srcsets)">
                <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 300vw, 75vw"
                    :type="'image/' + format.slice(1)">
            </template>
            <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 100vw, 75vw"
                :alt="teamMemberImageAltTag">
        </picture>

        <div class="copyright" v-if="teamMember.data.attributes.copyright">© {{ teamMember.data.attributes.copyright }}
        </div>
        <div class="info-container">
            <div class="info">
                <div class="info-name">
                    <router-link :to="`/team/${teamMember.data.attributes.slug}`"
                        v-if="teamMember.data.attributes.hasProfilePage">{{ teamMember.data.attributes.name
                        }}</router-link>
                    <div v-else>
                        {{ teamMember.data.attributes.name }}
                    </div>
                </div>
                <div class="info-position">
                    {{ localize(teamMember.data.attributes.position) }}
                </div>
                <div v-if="teamMember.data.attributes.hashtags" class="info-hashtags" :class="{closed: open}">
                    {{ localize(teamMember.data.attributes.hashtags) }}
                </div>
                <router-link v-if="teamMember.data.attributes.hasProfilePage"
                    :to="`/team/${teamMember.data.attributes.slug}`">
                    <button class="button-light">
                        Profil
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { ToggleTeamMember } from '@/events'

    export default {
    name: "TeamMember",
    props: [
        "teamMember",
        "teamMemberStyle",
        "placeholderImage"
    ],
    data() {
        return {
            open: false
        };
    },
    eventListeners: [
        {
            event: ToggleTeamMember,
            handler(event) {
                if (event.component != this) {
                    this.open = false;
                }
            }
        }
    ],
    methods: {
        goToProfile() {
            // navigate vue router to profile page
            if (this.teamMember.data.attributes.hasProfilePage) {
                this.$router.push(`/team/${this.teamMember.data.attributes.slug}`);
            }
        }
    },
    computed: {
        srcsets() {
            if (this.teamMember && this.teamMember?.data?.attributes?.image?.data) {
                return this.getImageSrcsets(this.teamMember.data.attributes.image.data.attributes);
            } else if (this.placeholderImage?.data?.attributes) {
                return this.getImageSrcsets(this.placeholderImage.data.attributes);
            }

            return null;
        },
        hasWebp() {
            return this.srcsets[".webp"] ? true : false;
        },
        originalFormat() {
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== ".webp") {
                    return format;
                }
            }
            return null;
        },
        teamMemberImageAltTag() {
            if (this.teamMember && this.teamMember.data.attributes.image.data) {
                return this.teamMember.data.attributes.image.data.attributes.alternativeText;
            }
            else if (this.placeholderImage) {
                return this.placeholderImage.data.attributes.alternativeText;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    $scrollbarPadding: 20px;

    @mixin openInfoContainer {
        @keyframes fade-in {
            0%   { opacity: 0; }
            100% { opacity: 1; }
        }

        @keyframes fade-out {
            0%   {opacity: 1; }
            100% { opacity: 0; }
        }

        background: linear-gradient(0deg, rgba(darken($timberGreen, 5), 0.97) 0%, rgba(darken($timberGreen, 5), 0.3) 100%);
        height: 100%;
        bottom: unset;
        top: 0;
        animation: fade-in linear 0.2s;
        -webkit-backdrop-filter: blur(10px) opacity(1);
        backdrop-filter: blur(10px) opacity(1);
    }
    @mixin openInfoHashtags {
        display: none;
    }
    @mixin openMoreInfo {
        height: auto;
    }
    @mixin openPlus {
        transform: rotate(0deg);
    }

    .team-member {
        position: relative;
        overflow: hidden;
        height: 640px;

        &.half_width {
            width: 50%;

            @include breakpoint('tablet') {
                width: 100%;
            }
        }

        &.third_width {
            width: 33.3333%;

            @include breakpoint('mobile') {
                width: 100%;
            }
        }

        &.quarter_width {
            width: 25%;

            @include breakpoint('tablet') {
                width: 50%;
            }
            @include breakpoint('mobile') {
                width: 100%;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .copyright {
            position: absolute;
            top: 0;
            right: 14px;
            transform: rotate(-90deg);
            transform-origin: 100% 100%;
            opacity: 0.5;
            font-family: getFontFamily('copyright');
            font-size: getFontSize('copyright', 'desktop');
            font-weight: getFontWeight('copyright');
            font-style: getFontStyle('copyright');

            @include breakpoint('tablet') {
                font-size: getFontSize('copyright', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('copyright', 'mobile');
            }
        }

        .info-container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(darken($timberGreen, 5), 0.97) 0%, rgba(darken($timberGreen, 5), 0) 100%);
            min-height: 50%;
            color: white;

            &.open {
                @include openInfoContainer;
            }

            .info {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: calc(100% - 100px);
                padding: 50px;
                padding-right: calc(50px - #{$scrollbarPadding});
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                .info-name, .info-name a {
                    font-family: getFontFamily('defaultText');
                    font-size: getFontSize('defaultText', 'desktop');
                    font-weight: getFontWeight('defaultText');
                    font-style: getFontStyle('defaultText');
                    text-transform: uppercase;
                    text-align: center;
                    margin-bottom: 10px;
                    //padding-right: $scrollbarPadding;
                }

                .info-position,
                .info-hashtags {
                    font-family: getFontFamily('smallText');
                    font-size: getFontSize('smallText', 'desktop');
                    font-weight: getFontWeight('smallText');
                    font-style: getFontStyle('smallText');
                    text-align: center;
                    margin-bottom: 15px;
                    //padding-right: $scrollbarPadding;
                }

                .info-hashtags.closed {
                    display: none;
                }

                .more-info {
                    height: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow-y: scroll;
                    padding-right: $scrollbarPadding;
                    scrollbar-width: none;
                    -ms-overflow-style: none;

                    &.open {
                        @include openMoreInfo
                    }

                    .telephone, .email {
                        font-family: getFontFamily('smallText');
                        font-size: getFontSize('smallText', 'desktop');
                        font-weight: getFontWeight('smallText');
                        font-style: getFontStyle('smallText');
                        text-align: center;
                        text-decoration: underline;
                        margin-bottom: 15px;
                    }

                    .social-icons {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        .icon {
                            padding: 8px;
                            height: 25px;
                            width: 25px;

                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }

                .more-info::-webkit-scrollbar { width: 0 !important }

                .plus-button-wrapper {
                    margin-top: 10px;
                    padding-right: $scrollbarPadding;

                    .plus {
                        transform: rotate(45deg);
                        width: 20px;
                        height: 20px;
                        cursor: pointer;

                        &.open {
                            @include openPlus;
                        }

                        &:hover {
                            opacity: 0.75;
                        }
                    }
                }
            }
        }

        /**@include breakpointAbove('tablet') {
            &:hover {
                .info-container {
                    @include openInfoContainer;

                    .info-hashtags {
                        @include openInfoHashtags;
                    }

                    .more-info {
                        @include openMoreInfo;
                    }

                    .info {
                        .plus-button-wrapper {
                            .plus {
                                @include openPlus;
                            }
                        }
                    }
                }
            }
        }*/

    }
</style>

<style lang="scss">
    .more-info {
        p, a {
            font-family: getFontFamily('smallText');
            font-size: getFontSize('smallText', 'desktop');
            font-weight: getFontWeight('smallText');
            font-style: getFontStyle('smallText');
            text-align: start;
            margin-bottom: 10px;
            margin-top: 0;
            color: white !important;

            strong {
                color: white !important;
            }
        }
    }
</style>
